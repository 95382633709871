import React from "react";

import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";

import locales from "../../../constants";

const AmazingThailand = ({ pageContext: { slug, lanKey } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "en";

  return (
    <Layout
      seo={{
        title: "Amazing Thailand",
        href: slug,
        lang: "en",
        // ogImage: require("../../../assets/img/portfolio/tourismAoT-og-image.jpg"),
        ogImage: require("../../../assets/img/portfolio/tourismAoT_og_image.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #df2cc6, #c523b0, #c230ad, #9200b9, #d64ac2 )",
        icons: "#9200b9",
        navClass: "tourismAoT__nav",
        ogImage: require("../../../assets/img/portfolio/tourismAoT_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/pl/projekty/amazing-thailand/",
      }}
    >
      <PortfolioHeader name="tourismAoT" height="260" />

      <section
        className="container-fluid portfolio-intro tourismAot-intro tourismAoT-section tourismAoT-section--1"
        id="info"
      >
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Amazing Thailand</h1>
              <ul>
                <li>Website</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner inner--divider">
              <p>
                In cooperation with Tourism Authority of Thailand we have
                created a new website, aimed at promoting the Kingdom of
                Thailand on the Polish market. We have designed a website,
                showing the various tourist attractions ​​that Thailand can be
                proud of. The site includes an interactive map of regions, a
                list and description of many historical monuments, beaches,
                sport and cultural attractions, and plenty of other key
                information that can be useful for anyone who wants to spend a
                great time in the Thai paradise, alone or with an entire family.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid tourismAoT-section tourismAoT-section--2">
        <div className="row">
          <div className="col-12 text-center">
            <div className="scroll_wrapper scroll_wrapper--macbook">
              <div className="img_wrapper">
                <picture>
                  <source
                    srcset={require("../../../assets/img/portfolio/tourismAoT_full.avif")}
                    type="image/avif"
                    alt="Strona Tourism Authority of Thailand"
                  />
                  <img
                    src={require("../../../assets/img/portfolio/tourismAoT_full.jpg")}
                    className="img-fluid"
                    alt="Strona Tourism Authority of Thailand"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid tourismAoT-section tourismAoT-section--3">
        <div className="tourismAoT-section__rectangle"></div>
        <div className="tourismAoT-section__rectangle"></div>

        <div className="row">
          <div className="col-12 col-lg-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/tourismAoT_page_main.jpg")}
              alt="Strona główna"
            />
          </div>
          <div className="col-12 col-lg-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/tourismAoT_page_massage.jpg")}
              alt="Strona główna"
            />
          </div>
          <div className="col-12 col-lg-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/tourismAoT_page_weather.jpg")}
              alt="Strona główna"
            />
          </div>
          <div className="col-12 col-lg-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/tourismAoT_phone.png")}
              alt="Strona główna"
            />
          </div>
          <div className="col-12 tourismAoT-section__banner">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/tourismAoT_banner.jpg")}
              alt=""
            />
          </div>
          <div className="col-12 col-lg-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/tourismAoT_page_entries.jpg")}
              alt="Strona główna"
            />
          </div>
          <div className="col-12 col-lg-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/tourismAoT_page_tips.jpg")}
              alt="Strona główna"
            />
          </div>
          <div className="col-12 col-lg-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/tourismAoT_page_academy.jpg")}
              alt="Strona główna"
            />
          </div>
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default AmazingThailand;
